
.columnContainer {
    background-color: white;
    /* border: solid 5px #96bfe0; */
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 18px;
    }
    P {
        font-size: 16px;
    }
/* HEADING OF SECTION */
  .heading {
    border: none;

    .card {  
        border: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        .card-body {

            .teamImg{
                img {
                   width: 30%;
                }
            }
            .card-header {
                background: #96bfe0;
                border-radius: 10%;
                font-weight: 600;
                font-size: 24px;
                }

            .card-text {
                padding-left: 10px;
                padding-right: 10px;

            }
        }
    }
  }

/* DETAIL OF SECTION */
  .collapse {
    width: 100%;
  }

  .cards-container {

    .card-container {
        /* width: 33vw; Setting this looks better on pc, but not mobile. */
        width: 30vw;
        margin: 1px;
        text-align: center;
        font-size: 10px;

        .card {
            display: flex;
            flex: 1;
            margin: 1px;
            text-align: center;
            border: none;

            .card-body {
                .teamImg{
                    img {
                        width: 50%;
                    }
                }
                .card-header {
                    background: #96bfe0;
                    border-radius: 10%;
                    border: none;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                }
                .card-text {
                    padding-left: 2px;
                    padding-right: 2px;
                    font-size: 16px;
                    border: none;
                }
            }
        }
     }
  }
    /* MOBILE DEVICE */
    @media (max-width: 768px) {
      .cards-container {
        display: flex;
        flex-flow: column;
        justify-content: center;

        .card-container {
            /* width: 33vw; Setting this looks better on pc, but not mobile. */
            width: 100vw;
            margin: 1px;
            text-align: center;
            font-size: 10px;
            } 
        }
    } 
    /* WIDE DEVICE */
    @media (min-width: 768px) { 
      .cards-container {
        display: flex;
        flex-flow: row wrap;
        flex: 3;
        justify-content: center;

        .card-container {
            width: 30vw;
            margin: 1px;
            text-align: center;
            font-size: 10px;
            } 
        }
    }
}