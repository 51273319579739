
:root   {

  --main-bg-color: #d3d4cd;
  
    --maincolor: #ffcdb2;
    --basecolor: #111013;
    --accentcolor1: #ffb4a2;
    --accentcolor2: #e5989b;
    --accentcolor3: #b5838d;
    --whitecolor: #ffffff;
      width: 100%;

}
body {
      width: 100%;


}
.container {
  
    /* background-color: rgb(69, 153, 209); */
    width: 100%;
}
.container-fluid {
    height: auto;
    background-color: hsl(223, 15%, 91%);
    width: 100%;
    padding: 0;
}
          
main {
      width: 100%;
      height: auto;

      .introCarousel {
          display: flex;
          flex: 1;
          max-height: 100%;

      }
  }

.app {
      font-family: "Varela", sans-serif;
      margin-bottom: 50px;
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;

  .container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;

      .link {
        text-decoration: none;
        color: inherit;
      }
  
 
   
    /*SINGLE*/

    .home {
        margin-top: 10px;
        margin-bottom: 50px;
        height: auto;

      .posts {

        .post{
           

          .img{
            img{
              height: 300px;
            }
            

          }
        }
      }

      .content {
  
        .edit-delete {

              img {
                width: 20px;
                height: 20px;
                margin-left: 20px;
                cursor: pointer;
              }   

        }

        .edit {
          display: flex;
          gap: 5px;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        h1 {
          font-size: 42px;
          color: #333;
        }

        p {
          text-align: justify;
          line-height: 30px;
        }
      }
 
    }


  }

  

  .imgOnly {
        background-color: grey;
                align-items: center;
        img {
                  width: 100%;
                  height: 70vh;
                }
          }

          .panelOverflow {
            overflow: auto;
          }

}


