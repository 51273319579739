/* Navbar.css */
nav.container{
  background-color: lightgray;
  /* opacity: .8; */
}
.header {
  position: fixed;
  width: 60%;
  top: 0;
  right: 0;
  /* z-index: var(--z-fixed); */
}

.nav {
  display: flex;
  align-items: right;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  
}

.nav__logo {
  color: var(--first-color);
  transition: color 0.4s;
  font-size: var(--h2-font-size);
  font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
    position: fixed;
    top: 0;
    right: 10%;
  
}

.nav__cta {
  background-color: lightgr;
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0rem 1.5rem;
}
/* Mobile Menu */
@media screen and (max-width: 1150px) {
  .nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 0%, 6%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 30%;
    height: 100%;
    
    transition: right 0.4s;

  .nav__list {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  }
}
@media screen and (max-width: 300px) {
  .nav__menu {
    padding: 4rem 1rem 2rem;
  }

  .nav__list {
    row-gap: 1rem;
  }

  .nav__link,
  .nav__cta {
    font-size: var(--smaller-font-size);
  }

  .nav__cta {
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 1rem;
    width: auto;
    white-space: nowrap;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}
.nav__list {
  display: flex;
  flex-direction: column;
  column-gap: 2.5rem;
}

.nav__menu_mobile {
  position: fixed;
  top: 0;
  right: -50%;
  background-color: hsla(0, 0%, 6%, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  width: 30%;
  height: 100%;
  padding: 16rem 3rem 0;
  /* transition: right 0.4s; */
} 
@media screen and (min-width: 1150px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }
  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
    padding-top: 2rem;
  }
}
  li {
  list-style: none;
}
