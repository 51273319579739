.uploadContainer {
    border: 5px solid lightgray;
    padding: 10px;
    
    margin: 2px;
    font-size: 12px;
    color: #555;

.uploadContainer {
    
    padding: 10px;}
    
label         {
     font-size: 16px;
  cursor: pointer;
     color: teal;
          margin: 2px;
          margin-right:2px;


          }

}
