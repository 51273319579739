div.footer{
      background-color: lightgrey;
      position: bottom;
      bottom: 0;
}
/* WIDE DEVICE */
@media (min-width: 768px) {
  div.footer {
    width: 100%;
    display: flex;
    flex: 2;
      flex-flow: row;
      height: auto;
    }
    .footer-contact {
      width: 50%;
      display: flex;
      flex: 2;
      flex-flow: row;
      height: auto;
    }
    
    .footer-location {
      width: 50%;
      display: flex;
      flex: 2;
      flex-flow: row;
      height: auto;
    }
    
 }
 /* MOBILE DEVICE */
 @media (max-width: 768px) {
    div.footer {
      width: 100%;
      display: flex;
      flex: 2;
      flex-flow: column;
      height: auto;
    }
    .footer-contact {
      width: 100%;
      display: flex;
      flex: 2;
      flex-flow: row;
      height: auto;
    }
    
    .footer-location {
      width: 100%;
      display: flex;
      flex: 2;
      flex-flow: row;
      height: auto;
    }
}

    .contact-title {
        text-align: center;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-stretch: expanded ;

            display: flex;
            margin: 10px;
            font-size: 18px;
            font-weight: bold;
            width: 20%;
      }
    .contact-info {
            display: flex;
            flex: 3;
            flex-flow: column;
            margin: 10px;
            width: 80%;
            padding: 10;
            font-size: 18px;

            .facebook {
                margin-left: 10px;
                min-height: 4.5vh;
                max-height: 4.5vh;
                padding: 0;
                border: none;
            }
      }


      .authContainer {
        display: flex;
        flex: 1;
        margin-top: 10px;
        min-height: 4.5vh;
        max-height: 4.5vh;
        padding: 0;
        border: none;

        a {
          height: 4.5vh;
          padding: 0;
          border: none;
        }

        div.auth-1 {
          display: flex;
          flex: 1;
          min-height: 4.5vh;
          max-height: 4.5vh;
          padding: 0;
          border: none;
        }
        div.auth-2 {
          display: flex;
          flex: 3;
          flex-flow: row;
          margin-right: 5px;

          min-height: 4.5vh;
          max-height: 4.5vh;
          padding: 0;
          border: none;

          .auth-link {
            margin-right: 20px;
          }
        }
      } 
    
  