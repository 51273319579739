
.intro-carousel {height: auto; margin-bottom: 10}
.carousel-caption {
    top: 60%; 

    h1 {
        background-color: lightgray;
        font-size: 24px; color: black; font-weight: 600;
        opacity: .8;
    } 
}

/* .carousel-inner { overflow: visible; } */
.carousel-control-next, .carousel-control-prev, .carousel-indicators 
{ 

    top: 10%; 
    /* width: 80px; */
    height: 10%;
       
}