 .top {
    font-family: "Varela", sans-serif;
    display: flex;
    flex: 2;
    flex-direction: column; 
    width: 100%;
    height: 10vh;
     position: sticky;
    top: 0;
    z-index: 1; 


    .menu {
      display: flex;
      align-content: center;
    }

/*    
    ${({ ishome }) => ishome === "true" && "width: 40%;"}
    ${({ ishome }) => ishome === "false" && "width: 40%;"}
 */
    .titleBar {
        display: flex;
        flex: 2;
        flex-direction: row;

         .topImg{
            img{
                width: 10vw;
            }
         }
         .centerTitle{
            display: flex;
            flex: 2;
            flex-direction: column;
            padding-left: 1rem;
            padding-top: 0rem;
            margin-top: 0rem;
         }

    }
  }
/*   .logo-only {
    font-family: "Varela", sans-serif;

    .logoBar {
        width: 10vw;
        align-content: left;

         .topImg{
            img{
                width: 100%;
            }
         }

    }

    .menu {
      display: flex;
      align-content: right;
    }
  } */
 