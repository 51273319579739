   /*LOGIN & REGISTER*/

    .auth {
      display: flex;
      flex-direction: column;
       align-items: center; 
      /* justify-content: center;  */
      min-height: 80vh;
      background-color: var(--main-bg-color);
       

      h1 {
        font-size: 20px;
        color: teal;
        margin-bottom: 0px;
      }

      form {
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: white;
        width: 400px;
        gap: 20px;

        input {
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;
        }

        button {
          padding: 10px;
          border: none;
          background-color: teal;
          cursor: pointer;
          color: white;
        }

        p {
          font-size: 12px;
          color: red;
          text-align: center;
        }

        span {
          font-size: 12px;
          text-align: center;
        }
        
      }
    }